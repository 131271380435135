import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import Vant from 'vant';
import 'vant/lib/index.css'
import 'lib-flexible/flexible'
import NutUI from "@nutui/nutui";
import "@nutui/nutui/dist/style.css";
import QrReader from 'vue3-qr-reader';


const app=createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }

app.use(ElementPlus, {
  locale: zhCn,
}).use(store).use(router).use(ElementPlus).use(Vant).use(NutUI).use(QrReader).mount('#app')



