import { createStore } from 'vuex'

export default createStore({
  state: {
    pageSize: 30,
    baseURL: "http://arful.cn:8084/android",
    staticHosturl: "http://arful.cn:80",
    scanResultList:(localStorage.getItem('scanDate')&&(JSON.parse(localStorage.getItem('scanDate'))))||[],
  },
  getters: {
    getScanResultList(state) {
     return state.scanResultList;
    }
  },
  mutations: {
    
    
  },
  actions: {
  },
  modules: {
    
    
  }
})
