<template>
  <div></div>
</template>

<script>
import {
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  onBeforeUpdate,
  onUpdated,
} from "vue";

import { homeSwitch } from "@/util/js/utils";

export default {
  name: "IndexVue",
  components: {},

  setup() {
    const data = reactive({});

    onBeforeMount(() => {});
    onMounted(() => {});
    onBeforeUpdate(() => {});
    onUpdated(() => {});

    //判断是移动端还是PC端

    homeSwitch();
    return {
      ...toRefs(data),
    };
  },
};
</script>

<style></style>
