

import { createRouter,createWebHashHistory } from 'vue-router'
import Index from '../views/layout/Index.vue'


const routes = [
   {//选择移动端还是桌面端
    path:'/',
    name:'Index',
    component:Index
  },
  {//手机登录
    path:'/mobile',
    name:'Mobile',
    component:()=>import('../views/pages/mobile/Mobile.vue')
  },

   {//桌面端登录
    path:'/windown',
    name:'Windown',
    component:()=>import('../views/pages/windowns/Windown.vue')
  },

    {//安卓应用展示
    path:'/showAndroid',
    name:'ShowAndroid',
    component:()=>import('../views/pages/components/ShowAndroid.vue')
  },
   {//windows应用展示
    path:'/showWindows',
    name:'ShowWindows',
    component:()=>import('../views/pages/components/ShowWindows.vue')
  },

   {//账号管理器windows应用展示
    path:'/showAccountWindows',
    name:'ShowccountWindows',
    component:()=>import('../views/pages/components/ShowAccountWindows.vue')
  },

   {//账号管理器安卓应用展示
    path:'/showAccountAndroid',
    name:'ShowccountAndroid',
    component:()=>import('../views/pages/components/ShowAccountAndroid.vue')
  },

   {//账号管理器隐私政策
    path:'/accountPrivacy',
    name:'AccountPrivacy',
    component:()=>import('../views/pages/components/AccountPrivacy.vue')
  },

 {//404页面
    path:'/:catchAll(.*)',
    name:'404',
    component:()=>import('../views/layout/404.vue')
  },
  {//微信扫一扫
    path:'/wXSaoyisao',
    name:'WXSaoyisao',
    component:()=>import('../components/WXSaoyisao.vue')
  },
 
]

const router = createRouter({
 // history: createWebHistory(process.env.BASE_URL),
  //使用哈希模式解决刷新丢失问题
 history: createWebHashHistory(),
  routes
})



export default router
