<template>
  <!-- <nav>
    <router-link to="/">LayOut</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <div>
    <router-view />
  </div>
</template>

<style>
body,
html {
  margin: 0;
  padding: 0;
}
/* flex布局类名 */
.flex-float {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex {
  display: flex;
  align-items: center;
}
.page_content {
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 20px;
  background: white;
  margin-top: 15px;
}
</style>
